import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  apiURL: string = environment.apiURL;
  constructor(private http: HttpClient) { }

  getSingleCourseWithLesson(id, user_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.get(this.apiURL+'/a/api/take_course/show/get_courses_with_lessons?cse=' + id + '&user_id=' + user_id);
  }

  getSingleCourseWithSection(course_id, section_id, user_id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.get(this.apiURL+'/a/api/take_course/show/get_lessons_by_section?cse=' + course_id + '&sectionid=' + section_id + '&user_id=' + user_id);
  }

  getSingleAssessment(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.get(this.apiURL+'/a/api/assessment/show/single?id=' + id);
  }

  startLesson(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.post(this.apiURL+'/a/api/take_course/add/start_lesson', body, httpOptions);
  }

  completeLesson(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.post(this.apiURL+'/a/api/take_course/add/completed_lesson', body, httpOptions);
  }

  markStartCourse(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.post(this.apiURL+'/a/api/take_course/add/start_course', body, httpOptions);
  }

  markCourseCompleted(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'X-Requested-With':  'XMLHttpRequest',
        'Authorization': ''
      })
    };
    return this.http.post(this.apiURL+'/a/api/take_course/add/completed_course', body, httpOptions);
  }

}
